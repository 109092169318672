.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #454851;
  padding: 10px;
  color: #fff;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-title {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #FCEFF9;
  font-size: 2rem;
  margin-left: 1rem;
  padding: 0.8rem;
  text-transform: uppercase;
  word-spacing: 5px;
  font-weight: 600;
}

.stethoscope-icon {
  margin-right: 20px;
  font-size: 3rem;
}

.navbar-right {
  display: flex;
}

.navbar-button {
  text-decoration: none;
  color: #fff;
  padding: 8px 12px;
  margin-left: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.navbar-button:hover {
  background-color: #0056b3;
}

/* Media query for mobile devices (up to 767px width) */
@media screen and (max-width: 767px) {
  .navbar {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }

  .navbar-left {
    margin-bottom: 10px;
  }

  .navbar-title {
    font-size: 1.5rem;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
  }

  .stethoscope-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .navbar-right {
    flex-direction: column;
  }

  .navbar-button {
    margin: 5px 0;
  }
}
