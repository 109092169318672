.hero {
  position: relative;
  height: 60vh;
  background-image: url('hero_image.jpeg'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 1rem;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 16px;
}

.hero-content h2 {
  font-size: 24px;
}

/* Media query for mobile devices (up to 767px width) */
@media screen and (max-width: 767px) {
  .hero {
    height: 100vh;
    width: 100%;
  }

  .hero-content {
    padding: 1rem;
    width: 80%;
  }

  .hero-content h1 {
    font-size: 32px;
  }

  .hero-content h2 {
    font-size: 20px;
  }
}
