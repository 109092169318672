.custom-component {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-color: #fff;
  color: #333;
  margin: 2rem;
}

.custom-component .image_main {
  
  
  object-fit: cover;
}


.image_main{
  width: 20%;
  height: 20%;
  border-bottom-right-radius: 1rem;
}
.custom-component .info_section {
  padding: 1.5rem;
  text-align: left;
}

.custom-component h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: whitesmoke;
}

.custom-component p {
  font-size: 1rem;
  line-height: 1.6;
  color: whitesmoke;
}

@media screen and (max-width: 767px) {
  .custom-component {
    margin: 1rem;
  }
}
